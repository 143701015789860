<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <p>Nombre del cupón</p>
        <v-text-field
          rounded
          :loading="loading"
          outlined
          :rules="[rules.required]"
          placeholder="Nombre para identificar el cupón"
          v-model.trim="coupon.name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <p>Descripción</p>
        <v-text-field
          rounded
          :loading="loading"
          placeholder="Descripción del cupón"
          v-model="coupon.description"
          outlined
        />
      </v-col>
      <v-col cols="12" md="6">
        <p>Restricciones aplican</p>
        <v-text-field
          rounded
          :loading="loading"
          placeholder="Detallar las restricciones del cupón."
          v-model="coupon.disclaimer"
          outlined
        />
      </v-col>

      <v-col cols="12" md="6">
        <p>Monto del descuento</p>
        <v-select
          rounded
          :rules="[rules.required]"
          outlined
          :loading="loading"
          :items="sortedAmounts"
          item-text="amount"
          item-value="amount"
          v-model="coupon.amount"
        >
          <template v-slot:item="data">
            <p class="ma-0 pa-0">
              {{ data.item.name }} (
              <span style="color: #FF1744" class="font-weight-bold">{{
                data.item.amount | currency
              }}</span>
              )
            </p>
          </template>

          <template v-slot:selection="data">
            <p class="ma-0 pa-0">
              {{ data.item.name }} (
              <span style="color: #FF1744" class="font-weight-bold">{{
                data.item.amount | currency
              }}</span>
              )
            </p>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <p>Fecha que inicia</p>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="true"
          :return-value.sync="date[0]"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              rounded
              :rules="[rules.required]"
              outlined
              v-on="on"
              placeholder="Fecha de inicio del cupón"
              v-model="date[0]"
            />
          </template>
          <v-date-picker
            v-model="date[0]"
            no-title
            scrollable
            :loading="loading"
            @click:date="setDate1"
            :min="today"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <p>Método de pago permitido</p>
        <v-select
          v-model="coupon.paymentGateways"
          :items="paymentGateways"
          placeholder="Seleccione los métodos de pago permitidos"
          outlined
          dense
          multiple
          item-value=".key"
          item-text="name"
          rounded
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">fas fa-question-circle</v-icon>
              </template>
              Métodos de pago permitidos para canjear el cupón. No seleccione
              ningún método de pago si desea que el cupón aplique para todos.
            </v-tooltip>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" md="6">
        <p>Seleccionar lista de beneficiados</p>
        <v-select
          rounded
          :loading="loading"
          :rules="[rules.required]"
          outlined
          :items="sortedBeneficiaries"
          item-text="name"
          item-value=".key"
          v-model="coupon.beneficiaries"
        >
          <template v-slot:item="data">
            <p class="ma-0 pa-0">
              {{ data.item.name }} (
              <span style="color: #FF1744" class="font-weight-bold">{{
                data.item.users.length 
              }} usuarios</span>
              )
            </p>
          </template>

          <template v-slot:selection="data">
            <p class="ma-0 pa-0">
              {{ data.item.name }} (
              <span style="color: #FF1744" class="font-weight-bold">{{
                data.item.users.length
              }} usuarios</span>
              )
            </p>
          </template>
        </v-select>
      </v-col>

      <v-col v-if="getTotal" cols="12" md="6">
        <div class="total mt-9 pt-2">
          <h2 class="pa-2 ma-0" style="color: #FF1744">
            Total a pagar : {{ getTotal | currency }}
          </h2>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import randomstring from "randomstring";
import { db } from "@/firebase";

export default {
  name: "coupon-general",
  props: [
    "coupon",
    "type",
    "date",
    "country",
    "amounts",
    "beneficiaries",
    "loading",
  ],
  data() {
    return {
      menu1: false,
      menu2: false,
      couponType: [
        { text: "Subtotal", value: "subtotal" },
        { text: "Total", value: "total" },
        { text: "Envío", value: "shipping" },
      ],
      // discountType: [
      //   { text: "Fijo", value: "amount" },
      //   { text: "Porcentual", value: "percent" },
      // ],
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v > 0) || "Debe ingresar un valor numerico mayor a 0",
        minNegative: (v) =>
          (v && v > -1) || "Debe ingresar un valor mayor o igual a 0",
      },
      // target: [
      //   { text: "Aplica a todo", value: "none" },
      //   { text: "Aplica a comercio", value: "businesses" },
      //   { text: "Aplica a categories", value: "categories" },
      //   { text: "Aplica a usuarios", value: "users" },
      // ],
      paymentGateways: [],
      generatingCode: false,
      // couponT: {
      //   minimumSale: 0,
      //   code: "",
      // },
    };
  },

  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  computed: {
    today() {
      return moment(new Date()).format("YYYY-MM-DD");
    },

    getTotal() {
      if (!this.coupon.beneficiaries || !this.coupon.amount) {
        return 0;
      }

      let beneficiaries = this.beneficiaries.find(
        (e) => e[".key"] == this.coupon.beneficiaries
      );

      return beneficiaries.users.length * this.coupon.amount;
    },

    maxDate() {
      return moment().add(1, "month").format("YYYY-MM-DD");
    },

    sortedBeneficiaries() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.beneficiaries.sort(compare);
    },
    sortedAmounts() {
      function compare(a, b) {
        if (a.amount < b.amount) return -1;
        if (a.amount > b.amount) return 1;
        return 0;
      }
      return this.amounts.sort(compare);
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    formatCode(e) {
      // console.log(e);
      this.coupon.code = e.toUpperCase();
    },

    setDate1(date) {
      this.$refs.menu1.save(date);
    },
    setDate2(date) {
      this.$refs.menu2.save(date);
    },
    async generateCode() {
      let code = `${
        this.coupon.name.replace(/\s+/g, "").toUpperCase().substring(0, 15) ||
        "HN"
      }-${randomstring.generate(4).toUpperCase()}`;
      this.generatingCode = true;

      let result = await db
        .collection("coupons4")
        .where("code", "==", code)
        .get();
      if (result.empty) {
        this.coupon.code = code;
        this.generatingCode = false;
      } else {
        this.generateCode();
      }
    },
  },
  async mounted() {
    await this.$binding(
      "paymentGateways",
      db
        .collection("paymentGateways")
        .where("deleted", "==", false)
        .where("countryId", "==", this.country)
        .where("active", "==", true)
        .orderBy("name")
    );
  },
};
</script>


<style scoped>
.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.total {
  background-color: #d1d1d1;
  padding: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
}

.fa-random {
  cursor: pointer;
  /* background-color: gray; */
  /* color: white; */
  font-size: 25px;
  color: gray;
  /* padding: 10px; */
}
</style>