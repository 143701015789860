<template>
  <v-row>
    <v-col v-if="loading">
      <v-row>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            ref="skeleton"
            type="image"
            class="mx-auto d-none d-md-block mt-5"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            ref="skeleton"
            type="image"
            class="mx-auto d-none d-md-block mt-5"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else cols="12">
      <a-transfer
        :listStyle="{
          height: '400px!important',
          width: '45%',
          background: 'white',
        }"
        :data-source="categories"
        :titles="['Todas las opciones', 'Opciones vinculadas']"
        :target-keys="optionsSelected"
        :selected-keys="selectedKeys"
        :show-search="true"
        @search="handleSearch"
        :render="(item) => item.title"
        :filter-option="filterOption"
        @change="handleChange"
        @selectChange="handleSelectChange"
        :locale="{
          itemUnit: '',
          itemsUnit: '',
          notFoundContent: 'Lista vacía',
          searchPlaceholder: 'Buscar sección',
        }"
      />
    </v-col>
  </v-row>
</template>

<script>
import transfer from "ant-design-vue/lib/transfer";
import { db } from "@/firebase";

export default {
  props: ["item", "city"],
  components: {
    "a-transfer": transfer,
  },
  data() {
    return {
      loading: true,
      categories: [],
      selectedKeys: [],
      optionsSelected: [],
    };
  },
  computed: {
    sortedCategories() {
      function compare(a, b) {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      }

      return this.categories.sort(compare);
    },
  },
  methods: {
    handleChange(nextTargetKeys) {
      this.item.categories = nextTargetKeys;
      this.optionsSelected = nextTargetKeys;
      // this.$emit("success", this.optionsSelected);
    },
    handleSearch(value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
  },
  async mounted() {
    this.$binding(
      "categories",
      db
        .collection("categories")
        .where("city", "==", this.city)
        .where("deleted", "==", false)
        .where("active", "==", true)
    ).then((categories) => {
      this.categories = categories.map((item) => {
        return {
          title: item.name ? item.name : "",
          key: item[".key"],
        };
      });
      this.categories = this.sortedCategories;
      this.loading = false;
    });

    this.optionsSelected = this.item.categories;
  },
};
</script>
