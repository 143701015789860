<template>
  <v-card>
    <v-card-title>
      <span class="headline">
        Resumen de {{ summaryCollection | translate }}
      </span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="array"
              :items-per-page="10"
              :loading="loading"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, 250],
              }"
            >
              <template v-slot:loading>
                <div class="ma-5" style="position: relative">
                  <div class="inner-lottie">
                     <lottie
                      :options="defaultOptions"
                      :height="150"
                      :width="150"
                      v-on:animCreated="handleAnimation"
                    />
                    <p
                      :class="
                        $vuetify.theme.dark
                          ? 'subtitle-1 primary--text'
                          : 'subtitle-1 black--text'
                      "
                    >
                      Cargando Data
                    </p>
                  </div>
                  <v-skeleton-loader
                    ref="skeleton"
                    type="table-tbody"
                    class="mx-auto d-none d-md-block"
                  ></v-skeleton-loader>
                </div>
              </template>

              <template v-slot:[`item.description`]="{ item }">
                <span v-if="summaryCollection == 'users'">
                  {{ item.name }} {{ item.surname }}
                  <v-chip class="ml-5">
                    {{ item.phone }}
                  </v-chip>
                </span>

                <span v-if="summaryCollection == 'businesses'">
                  <v-chip>
                    {{ item.shortName }}
                  </v-chip>
                </span>

                <span v-if="summaryCollection == 'categories'">
                  <v-chip>
                    {{ item.name }}
                  </v-chip>
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

export default {
  props: ["summaryCollection", "idsArray"],
  components: {
    lottie: Lottie,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      array: [],
      headers: [
        {
          text: "Descripción",
          value: "description",
          class: "nowrap",
        },
      ],
    };
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    getDocuments() {
      if (this.idsArray.length < 11) {
        db.collection(this.summaryCollection)
          .where(fb.firestore.FieldPath.documentId(), "in", this.idsArray)
          .get()
          .then((snapShots) => {
            snapShots.forEach((snap) => {
              this.array.push(Object.assign({ id: snap.id }, snap.data()));
            });
            this.loading = false;
          });
      } else {
        this.idsArray.forEach((id) => {
          db.collection(this.summaryCollection)
            .doc(id)
            .get()
            .then((doc) => {
              this.array.push(Object.assign({ id: doc.id }, doc.data()));
            });
        });
      }
    },
  },
  filters: {
    translate(word) {
      switch (word) {
        case "businesses":
          return "comercios";
        case "users":
          return "usuarios";
        case "categories":
          return "categorías";
        case "products":
          return "productos";
        default:
          return "generales";
      }
    },
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
