<template>
  <div id="add-edit-coupon">
    <v-card class="px-2">
      <v-card-title>
        <span class="headline">
          {{ type == "new" ? "Nuevo" : "Editar" }} cupón
        </span>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          :loading="loading"
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="general"> General </v-tab>
        <v-tab key="categories"> Categorías </v-tab>
        <v-tab key="businesses"> Comercios </v-tab>
      </v-tabs>

      <v-card-text>
        <v-container>
          <v-snackbar top :timeout="3000" v-model="snackbar">
            {{ snackbarText }}
          </v-snackbar>
          <v-overlay class="loading-center" :value="saving">
            <lottie
              :options="defaultOptions"
              :height="300"
              :width="400"
              v-on:animCreated="handleAnimation"
            />
          </v-overlay>

          <v-tabs-items v-model="tab">
            <v-tab-item key="general">
              <General
                :country="selectedCorporative.country"
                :date="date"
                :type="type"
                :loading="loading"
                :amounts="amounts"
                :coupon="coupon"
                :beneficiaries="beneficiaries"
              />
            </v-tab-item>

            <v-tab-item key="categories">
              <Categories :item="coupon" :city="selectedCorporative.city" />
            </v-tab-item>

            <v-tab-item key="businesses">
              <Businesses :item="coupon" :city="selectedCorporative.city" />
            </v-tab-item>
          </v-tabs-items>

          <v-row class="pa-0 ma-0 mt-8">
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="6">
              <v-row align="center" justify="start">
                <v-switch
                  :loading="loading"
                  v-model="coupon.active"
                  label="Cupón activo"
                ></v-switch>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="submit"
                  :loading="loading"
                  class="mt-4"
                  color="primary"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import Users from "./sections/Users";

import { db, fb } from "@/firebase";
import { mapState } from "vuex";

import { validationMixin } from "vuelidate";

import { required, minValue, between } from "vuelidate/lib/validators";

import General from "./sections/general.vue";

var randomstring = require("randomstring");

import Categories from "./sections/Categories";
import Businesses from "./sections/Businesses";

export default {
  name: "AddEditCoupon",
  components: {
    lottie: Lottie,
    Users,
    General,
    Categories,
    Businesses,
  },
  mixins: [lottieSettings, validationMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      tab: null,
      loading: false,
      saving: false,
      snackbarText: "",
      snackbar: false,
      date: [],
      amounts: [],
      beneficiaries: [],
      coupon: {
        active: true,
        amount: "",
        inventory: "",
        businesses: [],
        categories: [],
        // city: "",
        claimed: 0,
        code: "",
        // country: "",
        paymentGateways: [],
        // createdAt: new Date(),
        createdBy: "",
        deleted: false,
        description: "",
        disclaimer: "",
        from: "",
        maximumDiscount: "",
        sendPushNotification: false,
        minimumSale: "",
        name: "",
        to: "",
        type: "total",
        used: 0,
        usageAllowedByUser: 1,
        users: [],
        usedAt: [],
        bins: [],
      },
    };
  },
  computed: {
    ...mapState(["user", "selectedCorporative"]),
  },
  validations: {
    coupon: {
      inventory: { required, minValue: minValue(1) },
      name: { required },
      type: { required },
      code: { required },
      amount: {
        required,
        minValue: minValue(1),
      },
      // minimumSale: { required, minValue: minValue(0) },
      // maximumSale: { required, minValue: minValue(0) },
      // coveredByOcho: { between: between(0, 100) },
      // usageAllowedByUser: { required, minValue: minValue(1) },
      // maximumDiscount: { required, minValue: minValue(1) },
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();

      let validationResult = await this.validate();

      let code = `${"HN"}-${randomstring.generate(6).toUpperCase()}`;

      if (validationResult && code) {
        this.loading = true;
        this.coupon.code = code;
        this.coupon.from = this.date[0];
        this.coupon.country = this.selectedCorporative.country;
        this.coupon.city = this.selectedCorporative.city;
        this.coupon.isFixed = true;

        this.coupon.maximumDiscount = this.coupon.maximumDiscount
          ? this.coupon.maximumDiscount
          : 0;

        this.coupon.minimumSale = this.coupon.minimumSale
          ? this.coupon.minimumSale
          : 0;

        this.coupon.users = await this.getUsers();
        this.coupon.inventory = this.coupon.users.length;
        this.coupon.source = "corporateCoupons";
        this.coupon.corporateBusiness = this.selectedCorporative[".key"];

        var callableCorporativeCoupon = fb
          .functions()
          .httpsCallable("callableCorporativeCoupon");
        callableCorporativeCoupon(this.coupon)
          .then((result) => {
            // console.log(result);
            this.loading = false;
            this.$emit("success", "Cupóns agregado correctamente.");
          })
          .catch((err) => {
            // console.log(err);
            this.snackbarText = err.message;
            this.snackbar = true;
            this.loading = false;
          });
      }
    },

    getUsers() {
      return new Promise(async (resolve, reject) => {
        let list = await db
          .collection("corporateCoupons")
          .doc(this.selectedCorporative[".key"])
          .collection("beneficiaries")
          .doc(this.coupon.beneficiaries)
          .get();

        let phones = list.data().users.map((i) => {
          return `+${i.phone}`;
        });

        return resolve(phones);
      });
    },

    generateTimestamp(data, fl) {
      let date = data.split("-");
      return fb.firestore.Timestamp.fromDate(
        new Date(
          parseInt(date[0]),
          parseInt(date[1]) - 1,
          parseInt(date[2]),
          parseInt(fl == "from" ? "01" : "23"),
          parseInt(fl == "from" ? "00" : "59"),
          parseInt("00")
        )
      );
    },

    validate() {
      return new Promise(async (resolve) => {
        if (this.$v.coupon.name.$invalid) {
          this.showSnackBar("Debe ingresar el nombre del cupón");
          return resolve(false);
        }

        if (this.$v.coupon.amount.$invalid) {
          this.showSnackBar(
            "El monto de descuento del cupón debe ser mayor a cero."
          );
          return resolve(false);
        }
        if (!this.date[0]) {
          this.showSnackBar("Debe seleccionar la fecha inicial del cupón");
          return resolve(false);
        }

        return resolve(true);
      });
    },

    showSnackBar(message) {
      this.snackbarText = message;
      this.snackbar = true;
    },

    generateCode() {
      return new Promise(async (resolve, reject) => {
        let code = `${"HN"}-${randomstring.generate(6).toUpperCase()}`;
        return resolve(code);
      });
    },
  },

  async mounted() {
    await this.$binding(
      "amounts",
      db
        .collection("corporateCoupons")
        .doc(this.selectedCorporative[".key"])
        .collection("amounts")
        .where("deleted", "==", false)
        .where("active", "==", true)
    );

    await this.$binding(
      "beneficiaries",
      db
        .collection("corporateCoupons")
        .doc(this.selectedCorporative[".key"])
        .collection("beneficiaries")
        .where("deleted", "==", false)
        .where("active", "==", true)
    );
  },
};
</script>